import React from "react"
import { Link, graphql } from "gatsby"

import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import SocialSharing from "../components/socialsharing"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"
import AboutShort from "../components/aboutshort"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Is it time to"
          keywords={[`time`, `management`]}
        />
          <div className="row">
          <div className="col-lg-8 col-sm-12">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            var i = <div />
            if (node.frontmatter.cover_image){
              i = <Img 
                    fluid={node.frontmatter.cover_image.childImageSharp.fluid} 
                    alt={node.frontmatter.cover_image_caption}
                  />
            }
            return (
              <div key={node.fields.slug} className="card fluid">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <h3>
                      <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                    <p className="reduce-margin-top"><small>{node.frontmatter.date}</small></p>
                    <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  </div>
                  
                  <div className="col-lg-6 col-sm-12">
                    <p>
                      {i}
                    </p>
                  </div>
                </div>
                
                
                
              </div>
            )
          })}
          </div>
          
          
          <div className="col-lg-3 col-sm-12">
            <SocialSharing location={this.props.location} title={siteTitle} siteUrl={data.site.siteMetadata.siteUrl}/>
            <MailChimp />
            <PopularPosts />
          </div>
          </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC } limit:15) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            cover_image {
              childImageSharp {
                resize (width:1500, height: 1500) {
                  src
                }
                fluid (maxWidth: 786) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cover_image_caption
          }
        }
      }
    }
  }
`
